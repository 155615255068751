const EventPreview = ({eventImageURLString, eventName}) => {

    
    return (
        <>
            <div id="eventImgArea">
                <img  src={eventImageURLString} alt=""/>  
            </div>
            <h1 id="eventName">{eventName}</h1>
        
        </>
    )
}

export default EventPreview
