import React from 'react'
import { useContext , useState , useEffect , useRef } from 'react'
import { auth } from "../firebase"
import { onAuthStateChanged } from 'firebase/auth'

const AuthContext = React.createContext()

export const useAuth = () => {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setcurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const hasFetchedUser = useRef(false)

    useEffect(() => {
        if (!hasFetchedUser.current) {
            const unsubscribe = onAuthStateChanged(auth, user => {
                setcurrentUser(user)
                setLoading(false) 
                hasFetchedUser.current = true;
            })
            return unsubscribe(); 
        }    
    }, [currentUser]) 

    const value = {
        currentUser,
        setcurrentUser
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}


