import { useState, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate, useOutletContext } from "react-router-dom";
import "react-phone-number-input/style.css";
import { useAuth } from "../Contexts/AuthContext";
import { setDoc, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";

const SignIn = () => {
  const [phoneNumberInputValue, setphoneNumberInputValue] = useState(null);
  const [authCodeInputValue, setauthCodeInputValue] = useState("");
  const [nameInputValue, setnameInputValue] = useState("");
  const [phoneNumberSubmitted, setphoneNumberSubmitted] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [disableAuthCodeSubmit, setdisableAuthCodeSubmit] = useState(false);
  const [disablePhoneNoSubmit, setdisablePhoneNoSubmit] = useState(false);
  const navigate = useNavigate();
  const { setcurrentUser } = useAuth();
  const [id, dismissSignIn] = useOutletContext();
  console.log(id);

  // Render the invisble Recaptcha in the button
  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptchaContainer",
      { size: "invisible" },
      auth
    );
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (phoneNumberInputValue) {
      setdisablePhoneNoSubmit(true);
      if (isValidPhoneNumber(phoneNumberInputValue)) {
        const phoneNumber = phoneNumberInputValue;
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setphoneNumberSubmitted(true);
            seterrorMessage();
          })
          .catch((error) => {
            console.log(error);
            setphoneNumberInputValue("");
            seterrorMessage(
              "We could not send a one time password to that phone number."
            );
            setdisablePhoneNoSubmit(false);
          });
      } else {
        setphoneNumberInputValue("");
        seterrorMessage(
          "We could not send a one time password to that phone number. Please re-try."
        );
      }
    }
  };

  const handleCodeSubmit = (event) => {
    event.preventDefault();
    setdisableAuthCodeSubmit(true);
    const code = authCodeInputValue;
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        dismissSignIn();
        setcurrentUser(result.user);
        const { phoneNumber } = result.user;
        return phoneNumber;
      })
      .catch((error) => {
        setauthCodeInputValue("");
        console.log(error);
        seterrorMessage(
          "We were unable to verify your one time password. Please retry or go back to request a new one time password"
        );
        setdisableAuthCodeSubmit(false);
      })
      .then((phoneNumber) => {
        setDoc(
          doc(db, "users", phoneNumber, "information", "basic"),
          { number: phoneNumber, username: nameInputValue },
          { merge: true }
        );
        setDoc(
          doc(db, "users", phoneNumber, "eventCells2", id),
          {
            eventId: id,
            host: id.slice(0, id.indexOf("_")),
            identifier: id,
          },
          { merge: true }
        );
        updateDoc(
          doc(
            db,
            "users",
            id.slice(0, id.indexOf("_")),
            "posts",
            id,
            "private",
            id
          ),
          { eventMembers: arrayUnion(phoneNumber) }
        );
      });
  };

  return (
    <div className="pop-up-menu">
      {!phoneNumberSubmitted && (
        <form onSubmit={handleSubmit} className="content">
          <div className="prompt">
            <h1>enter your details to RSVP</h1>
          </div>
          <div className="large">
            <input
              type="text"
              autoFocus
              placeholder="Enter your name..."
              value={nameInputValue}
              onChange={(e) => setnameInputValue(e.target.value)}
            />
          </div>
          <div className="large">
            <PhoneInput
              placeholder="Enter your phone number..."
              defaultCountry="GB"
              value={phoneNumberInputValue}
              onChange={setphoneNumberInputValue}
              countryCallingCodeEditable={false}
              withCountryCallingCode
            />
          </div>
          <button
            className="large large-button large-button-dark"
            disabled={disablePhoneNoSubmit}
            id="recaptchaContainer"
          >
            RSVP
          </button>
        </form>
      )}
      {phoneNumberSubmitted && (
        <form onSubmit={handleCodeSubmit} className="content">
          <div className="prompt">
            <h1>enter your code</h1>
            <h2>sent to {phoneNumberInputValue}</h2>
          </div>
          <div className="large">
            <input
              placeholder="Enter here..."
              type="text"
              inputMode="numeric"
              required
              min="6"
              max="6"
              pattern="[0-9]*"
              value={authCodeInputValue}
              onChange={(e) => setauthCodeInputValue(e.target.value)}
            />
          </div>
          <button
            disabled={disableAuthCodeSubmit}
            className="large large-button large-button-dark"
          >
            see event
          </button>
        </form>
      )}

      {errorMessage && (
        <h4
          className="error"
          style={{ color: "#CF2E18", padding: "0px 1.56rem", fontSize: "1rem" }}
        >
          {errorMessage}
        </h4>
      )}
      {errorMessage && phoneNumberSubmitted && (
        <button
          style={{ color: "#CF2E18", padding: "0px 1.56rem", fontSize: "1rem" }}
          onClick={() => navigate(0)}
        >
          Go back
        </button>
      )}
    </div>
  );
};

export default SignIn;
