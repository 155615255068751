import React from 'react'
import mixerLogo from "../Assets/mixerLogo.png"


const Title = () => {
  return (
    <img src={mixerLogo} alt={<h1>&nbsp;<span id='M'>M</span><span id='I'>I</span><span id='X'>X</span><span id='E'>E</span><span id='R'>R</span></h1>} />
  
  )
}

export default Title