import React from "react";
import Logo from "../Components/Logo";
import appStoreIcon from "../Assets/appStoreIcon.jpeg";
import Title from "../Components/Title";

const Popup = ({ fetchError, responseWindow, setresponseWindow, eventId }) => {
  return (
    <div
      className="pop-up-menu"
      onClick={() => {
        responseWindow && setresponseWindow(false);
      }}
    >
      <div id="post-prompt">
        <div className="prompt">
          {fetchError && (
            <>
              <h1>Sorry...</h1>
              <h2>We couldn't find the event your were looking for.</h2>
            </>
          )}
          {responseWindow && (
            <>
              <h1>Thanks for Responding...</h1>
              <h2>
                To see who is invited, recieve updates or to create your own
                event - download our app!
              </h2>
            </>
          )}

          <Logo />
          <Title />
          <br />
          <a
            href={
              "https://mixerevent.page.link/?link=https://mixerapp.co.uk/event/" +
              eventId +
              "/?useapp=true" +
              "&ibi=OJONAS.entre&isi=1579685151"
            }
          >
            <img
              width="100rem"
              style={{ marginTop: "1rem" }}
              src={appStoreIcon}
              alt="Link"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Popup;
