import EventPreview from "./EventPreview"
import EventDetails from "./EventDetails"
import { useAuth } from '../Contexts/AuthContext'




const Event = (event) => {
    const {currentUser} = useAuth();
    const {authDone} = event;

     

    

    

    
            
    return (
        <>
            <div className="box"style={{borderBottom : !currentUser && "0.4px solid #07070C", paddingBottom: "0.8rem" }}>
                <EventPreview {...event}/>
                {currentUser && !authDone && <EventDetails {...event}/>}
            </div>
            
        </>
    )

};


export default Event