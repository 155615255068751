import React from 'react'
import dotLogo from "../Assets/dotLogo.png"

const Logo = () => {
  return (
    <div id="mixer-logo">
        <img src={dotLogo} />
    </div>
  )
}

export default Logo