import "../styles/App.scss";
import { AuthProvider } from "../Contexts/AuthContext";
import Welcome from "../Pages/Welcome";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Profile from "../Pages/Profile";
import Android from "../Pages/Android";
import SignIn from "./SignIn";

function App() {
  return (
    <>
      <section>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Profile />} />
              <Route path="event/:eventId" element={<Welcome />}>
                <Route path="signin" element={<SignIn />} />
              </Route>
              <Route path="android" element={<Android />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </section>
    </>
  );
}

export default App;
