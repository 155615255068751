import React from "react";
import { useState } from "react";
import Header from "../Components/Header";
import eventPage from "../Assets/eventPage.jpg";
import eventCreation from "../Assets/eventCreation.jpg";
import eventFeed from "../Assets/eventFeed.jpg";
import appStoreIcon from "../Assets/appStoreIcon.jpeg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Profile = () => {
  const [carouselIndex, setcarouselIndex] = useState(0);
  return (
    <>
      <Header />
      <div className="prompt">
        <br />
        <h1 className="zero">Your events, made easy</h1>
        <br />
        {carouselIndex === 0 && (
          <h2 className="zero">Create an event in seconds.</h2>
        )}
        {carouselIndex === 1 && (
          <h2 className="one">Share across all forms of social media.</h2>
        )}
        {carouselIndex === 2 && <h2 className="two">Bring people together!</h2>}
        <br />
        <div className="carouselHello">
          <Carousel
            autoPlay
            interval={4000}
            infiniteLoop
            showStatus={false}
            showArrows={false}
            swipeable={false}
            width={"60vmin"}
            showThumbs={false}
            onChange={(index) => setcarouselIndex(index)}
          >
            <div className="homeImages">
              <div className="homeImageArea">
                <img src={eventCreation} alt="" />
              </div>
            </div>
            <div className="homeImages">
              <div className="homeImageArea">
                <img src={eventFeed} alt="" />
              </div>
            </div>
            <div className="homeImages">
              <div className="homeImageArea">
                <img src={eventPage} alt="" />
              </div>
            </div>
          </Carousel>
          <br />
          <img src={appStoreIcon} alt="" />
        </div>
      </div>
    </>
  );
};

export default Profile;
