import { useEffect, useState } from "react";
import { useParams, useNavigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import Header from "../Components/Header";
import Event from "../Components/Event";
import Popup from "../Components/Popup";
import { fetch, idFetch, fullFetch } from "../Functions/fetch";

const Welcome = () => {
  const location = useLocation();
  const { eventId } = useParams();
  const { currentUser } = useAuth();
  const [loaded, setloaded] = useState(false);
  const [authDone, setauthDone] = useState(false);
  const [signInOpen, setsignInOpen] = useState(
    location.pathname.includes("signin") && !currentUser ? true : false
  );
  const [event, setevent] = useState();
  const [responseWindow, setresponseWindow] = useState(false);
  const [fetchError, setfetchError] = useState();
  const navigate = useNavigate();

  const dismissSignIn = () => {
    setauthDone(true);
    setsignInOpen(false);
    navigate("./");
  };
  const onlineResponse = (e) => {
    e.preventDefault();
    setsignInOpen(true);
    navigate("signin");
  };
  const appRedirect = (e) => {
    e.stopPropagation();
    console.log("hello");
  };

  useEffect(() => {
    if (!fetchError && !loaded) {
      if (!currentUser) {
        idFetch(eventId)
          .then((id) => {
            return fetch(id);
          })
          .then((eventInfo) => {
            setevent(eventInfo);
            console.log("fetch");
            setloaded(true);
          })
          .catch(() => setfetchError(true));
      } else if (currentUser) {
        idFetch(eventId)
          .then((id) => {
            return fullFetch(id);
          })
          .then((eventInfo) => {
            setevent(eventInfo);
            console.log("fullFetch");
            setauthDone(false);
            setloaded(true);
          })
          .catch(() => setfetchError(true));
      }
    } else if (authDone) {
      fullFetch(event.id).then((eventInfo) => {
        setevent(eventInfo);
        console.log("fullFetchOnly");
        setauthDone(false);
      });
    }
  }, [eventId, currentUser, loaded, authDone, fetchError]);

  useEffect(() => {
    if (loaded) {
      document.title = event.eventName;
    }
  }, [event, loaded]);

  return (
    <>
      {!(signInOpen || responseWindow || fetchError) && <Header />}

      <section>
        {loaded && (
          <div className="content">
            <Event
              {...event}
              authDone={authDone}
              responseWindow={responseWindow}
              setresponseWindow={setresponseWindow}
            />
          </div>
        )}

        {!currentUser && (
          <div className="advance">
            <a
              href={
                "https://mixerevent.page.link/?link=https://mixerapp.co.uk/" +
                "/?useapp=true" +
                "/event/" +
                eventId +
                "&ibi=OJONAS.entre&isi=1579685151"
              }
            >
              <button
                onClick={(e) => appRedirect(e)}
                className="large large-button large-button-dark"
              >
                USE APP
              </button>
            </a>
            <button
              className="large large-button blue"
              onClick={(e) => onlineResponse(e)}
            >
              RESPOND ONLINE
            </button>
          </div>
        )}
        {loaded && !currentUser && (
          <Outlet context={[event.id, dismissSignIn]} />
        )}
      </section>

      {responseWindow && (
        <Popup
          responseWindow={responseWindow}
          setresponseWindow={setresponseWindow}
          eventId={eventId}
        />
      )}
      {fetchError && <Popup fetchError={fetchError} eventId={eventId} />}
    </>
  );
};
export default Welcome;
