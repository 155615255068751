import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import Title from "../Components/Title";
import LogOut from "./LogOut";
import { useAuth } from "../Contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Header = ({ signInOpen }) => {
  const [hamburgerOpen, sethamburgerOpen] = useState(false);
  const { currentUser } = useAuth();
  const { pathname } = useLocation();

  const hamburger = () => {
    sethamburgerOpen(!hamburgerOpen);
  };

  return (
    <div id="full">
      <nav>
        <div id="heading">
          {!hamburgerOpen && !signInOpen && (
            <button onClick={hamburger} id="hamburger">
              <MenuIcon fontSize="large" />
            </button>
          )}
          {hamburgerOpen && !signInOpen && (
            <button onClick={hamburger} id="hamburger">
              <CloseIcon fontSize="large" />
            </button>
          )}
          <div id="title">
            <Title />
          </div>
          <a
            href={
              "https://mixerevent.page.link/?link=https://mixerapp.co.uk" +
              pathname +
              "/?useapp=true" +
              "&ibi=OJONAS.entre&isi=1579685151"
            }
          >
            <button className="blue" id="app-prompt">
              Use app
            </button>
          </a>
        </div>
      </nav>
      {hamburgerOpen && (
        <div className="pop-up-menu pop-up-menu-hamburger">
          <Link to={"/"}>
            <button className="menu-button blue">Home</button>
          </Link>
          {currentUser && <LogOut />}
        </div>
      )}
    </div>
  );
};

export default Header;
