import React from 'react'
import { signOut } from "firebase/auth";
import { auth } from "../firebase"
import { useNavigate } from "react-router-dom"



const LogOut = () => {
    const navigate = useNavigate();

    const handleLogOut = () => {
        signOut(auth).then(() => {
            navigate(0)
        // Sign-out successful.
        }).catch((error) => {
        // An error happened.
            console.log("sign out failed")
        });    
    }
    
    return (
        <button className="blue" onClick={handleLogOut}>Log OUT</button>
    )
}

export default LogOut
