import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

export const idFetch = async (eventId) => {
  const doccy = await getDoc(doc(db, "eventPins", eventId));
  if (doccy.exists()) {
    const { id } = doccy.data();
    return id;
  }
};

export const fetch = async (id) => {
  const docky = await getDoc(
    doc(db, "users", id.slice(0, id.indexOf("_")), "posts", id)
  );
  const eventInfo = docky.data();
  eventInfo.id = id;
  return eventInfo;
};

export const fullFetch = async (id) => {
  const info = await getDoc(
    doc(db, "users", id.slice(0, id.indexOf("_")), "posts", id, "private", id)
  );
  const eventObject = info.data();
  eventObject.id = id;
  return eventObject;
};
