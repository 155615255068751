import React, { useState , useEffect, useRef } from 'react'
import { updateDoc , arrayUnion , arrayRemove } from "firebase/firestore";
import { useAuth } from '../Contexts/AuthContext';
import { doc } from 'firebase/firestore';
import {db} from "../firebase"



const EventRsvp = ({goers , noers , maybeers, hostName, id, responseWindow, setresponseWindow}) => {
    
    const {currentUser} = useAuth();
    const eventDocRef = useRef();
    const [rsvpStatus, setrsvpStatus] = useState()
    const [going, setgoing] = useState()
    

    
    useEffect(() => {
        eventDocRef.current = doc(db, "users", id.slice(0, id.indexOf("_")), "posts", id, "private", id)
        goers.includes(currentUser.phoneNumber) ? setrsvpStatus("goers") : (maybeers.includes(currentUser.phoneNumber) ? setrsvpStatus("maybeers") : (noers.includes(currentUser.phoneNumber) && setrsvpStatus("noers")));
        setgoing(goers.length)
    },[goers, maybeers, noers, currentUser.phoneNumber, id])

    


    return (
        <>
            {going > 2 && 
                <h2>{going + " going"}</h2>
            }

            <form id="rsvpForm">
                <input 
                    onChange={(e) => {
                        if(rsvpStatus === "maybeers") { 
                            updateDoc(eventDocRef.current, {
                                "maybeers" : arrayRemove(currentUser.phoneNumber)
                            });
                        } else if (rsvpStatus === "noers") {
                            updateDoc(eventDocRef.current, {
                                "noers" : arrayRemove(currentUser.phoneNumber)
                            });
                        }
                        setrsvpStatus(e.target.value)
                        updateDoc(eventDocRef.current, {
                            "goers" : arrayUnion(currentUser.phoneNumber)
                        });
                        setgoing(() => going + 1)
                        setresponseWindow(true)
                    }}
                    type="radio" 
                    id="going"
                    name="rsvpOption" 
                    value="goers"
                />
                <label htmlFor="going" style={{backgroundColor: rsvpStatus === "goers" && "#626d53", color: rsvpStatus === "goers" && "#f9f9f9"}}>Going</label>
                <input
                    onChange={(e) => {
                        if(rsvpStatus === "goers") { 
                            updateDoc(eventDocRef.current, {
                                "goers" : arrayRemove(currentUser.phoneNumber)
                            });
                            setgoing(() => going - 1)
                        } else if (rsvpStatus === "noers") {
                            updateDoc(eventDocRef.current, {
                                "noers" : arrayRemove(currentUser.phoneNumber)
                            });
                        }

                        setrsvpStatus(e.target.value)
                        updateDoc(eventDocRef.current, {
                            "maybeers" : arrayUnion(currentUser.phoneNumber)
                        });
                        setresponseWindow(true)
                        }}
                    type="radio" 
                    id="maybe" 
                    name="rsvpOption" 
                    value="maybeers" 
                />
                <label htmlFor="maybe" style={{backgroundColor: rsvpStatus === "maybeers" && "#d8b3c7", color: rsvpStatus === "maybeers" && "#f9f9f9"}}>Maybe</label>
                <input 
                    onChange={(e) => {
                        if(rsvpStatus === "maybeers") { 
                            updateDoc(eventDocRef.current, {
                                "maybeers" : arrayRemove(currentUser.phoneNumber)
                            });
                        } else if (rsvpStatus === "goers") {
                            updateDoc(eventDocRef.current, {
                                "goers" : arrayRemove(currentUser.phoneNumber)
                            });
                            setgoing(() => going - 1)
                        }
                        setrsvpStatus(e.target.value)
                        updateDoc(eventDocRef.current, {
                            "noers" : arrayUnion(currentUser.phoneNumber)
                        });
                        setresponseWindow(true)
                    }}
                    type="radio" 
                    id="cantGo" 
                    name="rsvpOption"  
                    value="noers"
                />
                <label htmlFor="cantGo" style={{backgroundColor: rsvpStatus === "noers" && "#cf2e18", color: rsvpStatus === "noers" && "#f9f9f9"}}>Can't go</label>
            </form>
            
        </>
    )
}

export default EventRsvp