import React from 'react'
import Title from '../Components/Title'
import Logo from '../Components/Logo'
import { useNavigate } from 'react-router-dom'

const Android = () => {
    const navigate = useNavigate();

    const backToEvent = () => {
        navigate(-1)
    }
  return (
    <div id="post-prompt">
        <div className="prompt">
            <h1>Our android app will be available soon...</h1>
            <h2>We're working on it day and night!  </h2>
            <Logo/>
            <Title/>
            <br />
            <br />
            <button className='large large-button large-button-dark' onClick={() => backToEvent}>back to event</button>
        </div>
    </div>
  )                    
}

export default Android