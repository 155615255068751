import moment from 'moment';
import EventRsvp from './EventRsvp';


const EventDetails = (event) => {
    const {eventLocation, eventDescription, eventStart } = event;
    const date = new Date(`${eventStart.slice(0, (eventStart.lastIndexOf("a") - 1))} ${eventStart.slice((eventStart.lastIndexOf("t") + 2))}`)
    const day = moment(date).format('dddd');
    const monthDay = moment(date).format('D MMMM')
    const time = moment(date).format('h:mm a')


    
    return (
        <div className="eventDetails">
            <h2>{time} on {day} {monthDay}</h2>
            <h2>{eventLocation} </h2>
            <EventRsvp {...event}/>
             <div id='details'>  
                <h2>{eventDescription}</h2>
            </div>
        </div>
    )
}

export default EventDetails
